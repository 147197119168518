import React from 'react'
import classnames from 'classnames'
import Layout from '../containers/Layout'
import privacy from '../config/text/privacy.json'

class Privacy extends React.Component {
  render () {
    return (
      <Layout heroSrc='heros/coffee-beans-box.jpg' heroText={privacy.title} title={privacy.meta.title}>
        <div className='container privacy'>
          <div className='row'>
            {privacy.description.map(d => {
              return (
                <div className={classnames('col-xs-12', {'my-1': d.title})}>
                  {d.title ? (
                    <React.Fragment>
                      <h4>{d.title}</h4>
                      {d.description.map(t => <p>{t}</p>)}
                    </React.Fragment>
                  ) : (
                  <p>{d}</p>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default Privacy
